import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { Metric } from 'web-vitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if (process.env.REACT_APP_BUILD_ENV === 'production') {
  const sendToGoogleAnalytics = ({
    name,
    delta,
    id
  }: Partial<Metric>): void => {
    if (name) {
      gtag('event', name, {
        event_category: 'Web Vitals',
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID.
        //(note: requires `eventLabel` to be a dimension in your report).
        event_label: id,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: delta
          ? Math.round(name === 'CLS' ? delta * 1000 : delta)
          : undefined,
        // Use a non-interaction event to avoid affecting bounce rate.
        non_interaction: true
      });
    }
  };
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(sendToGoogleAnalytics);
}
