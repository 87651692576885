import React from 'react';
import styles from './App.module.scss';
import { Card } from './Card/Card';
import AppService from '../Services/AppService';
import { Customer, Officer, Incident } from '../Models';

const service = new AppService();

const App = (): React.ReactElement<{}> => {
  const customer: Customer = service.getCustomer();
  const officer: Officer = service.getOfficer();
  const incident: Incident = service.getIncident();
  const eventIdentifier: string | undefined = service.getEventIdentifier();
  const notes: string | undefined = service.getNotes();

  return (
    <div className={styles.app}>
      <h1 style={{ display: 'none' }}>Incident Public Site</h1>
      <main>
        <Card
          customer={customer}
          officer={officer}
          incident={incident}
          eventIdentifier={eventIdentifier}
          notes={notes}
        />
      </main>

      <footer>Powered by SmartForce Technologies, Inc.</footer>
    </div>
  );
};

export default App;
