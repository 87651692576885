import React from 'react';
import styles from './Card.module.scss';
import { CardHeader } from './CardHeader/CardHeader';
import { CardBody } from './CardBody/CardBody';
import { Customer, Officer, Incident } from '../../Models';

export interface CardProps {
  customer: Customer;
  officer: Officer;
  incident: Incident;
  eventIdentifier: string | undefined;
  notes: string | undefined;
}

export const Card = ({
  customer,
  officer,
  incident,
  eventIdentifier,
  notes
}: CardProps): React.ReactElement<CardProps> => {
  return (
    <div className={styles.card}>
      <CardHeader
        name={customer.name}
        state={customer.state}
        badgeUrl={customer.badgeUrl}
      />

      <hr />

      <CardBody
        customer={customer}
        officer={officer}
        incident={incident}
        eventIdentifier={eventIdentifier}
        notes={notes}
      />
    </div>
  );
};
