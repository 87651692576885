function mask(value: string, pattern: string) {
  let i = 0;
  return pattern.replace(/_/g, () => {
    const nextChar = value[i++];
    return nextChar ?? '';
  });
}

export function getPhoneLabel(phoneNumber: string) {
  return mask(phoneNumber, '(___) ___-____');
}

export function getWebSiteLabel(webSite: string) {
  return webSite.replace(/http[s]?:\/\//, '');
}

export function getMailTo(
  address: string,
  officerName: string,
  officerId: string,
  incidentNumber?: string
) {
  let header: string = `${address}?subject=`;
  if (incidentNumber) {
    header += `Incident Number ${incidentNumber}`;
  }

  let body: string = `&body=To Whom It May Concern%2c%0A%0AIn regards to:%0A`;
  if (incidentNumber) {
    body += `Incident Number ${incidentNumber}%0A`;
  }
  body += `Officer ${officerName}%0A`;
  body += `Officer ID ${officerId}%0A`;
  body += '%0AMessage%0A%0APreferred Contact Method:%0A%0ASincerely%2c';

  return header + body;
}

export const getStringAbbreviation = (value: string) => {
  const abbreviation = value.split(' ');
  let stringAbbreviation: string = '';

  if (abbreviation) {
    stringAbbreviation = abbreviation[0][0];

    if (abbreviation.length >= 2) {
      stringAbbreviation += abbreviation[1][0];
    }

    if (abbreviation.length >= 3) {
      stringAbbreviation += abbreviation[2][0];
    }
  }

  return stringAbbreviation;
};
