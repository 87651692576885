import React from 'react';
import { getStringAbbreviation } from '../../../Helpers';
import styles from './CardHeader.module.scss';

export interface HeaderProps {
  name?: string;
  state?: string;
  badgeUrl?: string;
}

export const CardHeader = ({
  name,
  state,
  badgeUrl
}: HeaderProps): React.ReactElement<HeaderProps> => {
  return (
    <div className={styles.header}>
      <div className={styles.badgeContainer}>
        {badgeUrl && <img src={badgeUrl} alt="Badge" />}
        {!badgeUrl && name && (
          <span className={styles.noBadge}>{getStringAbbreviation(name)}</span>
        )}
      </div>

      {name && <h2>{name}</h2>}

      {state && <h3>{state}</h3>}
    </div>
  );
};
